import { ErrorMessageReferenceKey } from '@/types/error';

import { BlockListItem, ContentPageSuffix, ErrorMessage, Offer, OfferListBlock, OfferListItem } from './generalCmsData';
import { Product } from '../product';

export enum CmsProductType {
  Contract = 'Contract',
  ExtraUser = 'Extra user',
  NonContract = 'Non-contract',
}

export type ChangeSubscriptionProductSection = {
  orphanNotice?: string;
  orphanNoticeNoPotentialCompanions?: string;
  orphanOffer?: Offer;
  productSectionChangeToSectionButtonText: string;
  productSectionChangeWithinSectionButtonText: string;
  productSectionCompanionSubscriptionChangeLink?: string;
  productSectionCompanionSubscriptionHeader?: string;
  productSectionDowngradeWarning: string;
  productSectionHeader: string;
  productSectionOffers: BlockListItem<OfferListItem | OfferListBlock>[];
  productSectionUsps?: {
    $values: string[];
  };
  productType: CmsProductType;
  requiresAuthentication: string;
  authenticationBody: string;
  authenticationButtonText: string;
  selectCompanionButtonText?: string;
};

export type ChangeSubscriptionCmsData = {
  accordionB2BvatInformation: string;
  accordionContractCampaignFineprint: string;
  accordionContractCurrentPrice: string;
  accordionHeader: string;
  accordionNonContractPrice: string;
  accordionProductSectionsB2Bisp: BlockListItem<ChangeSubscriptionProductSection>[];
  accordionProductSectionsB2Bvoice: BlockListItem<ChangeSubscriptionProductSection>[];
  accordionProductSectionsB2Cisp: BlockListItem<ChangeSubscriptionProductSection>[];
  accordionProductSectionsB2Cvoice: BlockListItem<ChangeSubscriptionProductSection>[];
  backLinkText: string;
  changeSubscriptionHeader: string;
  confirmationModalBody: string;
  confirmationModalButtonText: string;
  confirmationModalHeader: string;
  errorsList: BlockListItem<ErrorMessage<ErrorMessageReferenceKey | string>>[];
  priceplanCampaignText: string;
  priceplanContract: string;
  priceplanExpiredNonContractBroadband: string;
  priceplanExpiredNonContractVoice: string;
  priceplanHeader: string;
  priceplanNonContract: string;
  priceplanValidNonContract: string;
  metaData: {
    productData: Product[];
  };
} & ContentPageSuffix;
