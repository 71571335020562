export enum NumberPortStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  WaitingForCustomer = 'WaitingForCustomer',
  WaitingForCustomer2 = 'WaitingForCustomer2',
  WaitingForDonor = 'WaitingForDonor',
  Confirmed = 'Confirmed',
  Rejected = 'Rejected',
  Rejected2 = 'Rejected2',
  Cancelled = 'Cancelled',
  WaitingForActivation = 'WaitingForActivation',
  WaitingForUpdateComplete = 'WaitingForUpdateComplete',
  WaitingForPortDate = 'WaitingForPortDate',
  Error = 'Error',
  WaitingForAuth1 = 'WaitingForAuth1',
  WaitingForAuth2 = 'WaitingForAuth2',
  WaitingForCreateResponse = 'WaitingForCreateResponse',
  WaitingForCancelResponse = 'WaitingForCancelResponse',
  WaitingForChange = 'WaitingForChange',
  WaitingForTermination = 'WaitingForTermination',
  WaitingForAgeingReset = 'WaitingForAgeingReset',
  FlowRestarted = 'FlowRestarted',
  CancellationPending = 'CancellationPending',
  StatusChanging = 'StatusChanging',
  Unknown = 'Unknown',
}

export enum NumberPortCmsAlias {
  PendingInitial = 'PENDING_INITIAL',
  PendingWaitingForDonor = 'PENDING_WAITING_FOR_DONOR',
  PendingWaitingForDonorExport = 'PENDING_WAITING_FOR_DONOR_EXPORT',
  PendingWaitingForActivation = 'PENDING_WAITING_FOR_ACTIVATION',
  PendingConfirmed = 'PENDING_CONFIRMED',
  PendingConfirmedExport = 'PENDING_CONFIRMED_EXPORT',
  RejectedBindingPeriodLeft = 'REJECTED_BINDING_PERIOD_LEFT',
  RejectedCompanySeriesCantBeBroken = 'REJECTED_COMPANY_SERIES_CANT_BE_BROKEN',
  RejectedUnregisteredSim = 'REJECTED_UNREGISTERED_SIM',
  RejectedOtherOwnerB2b = 'REJECTED_OTHER_OWNER_B2B',
  RejectedOtherOwner = 'REJECTED_OTHER_OWNER',
  RejectedGenericError = 'REJECTED_GENERIC_ERROR',
  ApiError = 'ERROR_API_ERROR',
}

export enum PortingRejectedCode {
  BindingPeriodLeft = '10', // 010 Binding agreement exists = Binding period left at the current operator.
  CompanySeriesCantBeBroken = '2', // 002 Number part of a DDI-range that cannot be ported = number part of a company’s number series that cannot be broken.
  UnregisteredSim = '11', // 011 Unknown prepaid subscription = Unregistered pre-paid SIM
  OtherOwner = '3', // 003 Number not associated with stated subscriber = Someone else (person or company) owns the phone number at the previous operator
}

export type NumberPort = {
  id?: string;
  caseText?: string;
  code?: string;
  phoneNumber?: string;
  portDate?: string;
  rejectCode?: string;
  rejectComment?: string;
  signupDate?: string;
  status: NumberPortStatus;
  statusText?: string;
  portInType?: 'Import' | 'Export';
  numberType?: string;
};
