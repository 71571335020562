import { CmsPageType } from '@/constants';
import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { Brand } from '@/types/account';
import { BlockListItem, CmsHeaderBody, CmsHelpText, Image, OfferListBlock, Usp } from '@/types/cms/generalCmsData';
import { BlockListItemType, CmsPageSeo } from '@/types/cms/startPageCmsData';
import { ErrorMessageWrappedInContent } from '@/types/error';
import { Product } from '@/types/product';

export enum StockStatus {
  InStock = 'InStock',
  FewInStock = 'FewInStock',
  OutOfStock = 'OutOfStock',
}

export enum SaleStatus {
  ComingSoon = 'ComingSoon',
  Preorder = 'Preorder',
  OnSale = 'OnSale',
}

export type HardwareItemStatus = StockStatus | SaleStatus;

export enum HardwareBrand {
  Apple = 'Apple',
  Samsung = 'Samsung',
  Huawei = 'Huawei',
}

export enum HardwareCategory {
  Mobile = 'Mobile',
  Router = 'Router',
}

export enum HardwareSalesStatus {
  OnSale = 'OnSale',
  ComingSoon = 'ComingSoon',
  Preorder = 'Preorder',
}

export type HardwareColor = {
  colorCode: string;
  colorName: string;
  images: Image[];
};

// Todo this with real values
export type HardwareCampaign = {
  $type: 'HardwareSubscriptionCampaign'; // can this be more types?
  campaignHeader: string;
  campaignText: string;
  campaignCollapsedBody?: string;
  campaignCollapsedLabel?: string;
  campaignExpandedLabel?: string;
  existingSubscriptionCampaignOffers: BlockListItem<OfferListBlock>[];
  memorySize: string;
  newSubscriptionCampaignOffers: BlockListItem<OfferListBlock>[];
  stickerText: string;
};

export type HardwareInstalment = {
  discountEndDate: string;
  discountedTotalPrice: string;
  numberOfInstalments: string;
  totalPrice: string;
  totalPriceExclVat: string;
  discountedTotalPriceExclVat: string;
};

export type HardwareVariant = {
  colorName: string;
  ean: string;
  instalmentOptions: BlockListItem<HardwareInstalment>[];
  memorySize: string;
  productReference: string;
  ratorId: string;
  temporarilyUnavailable: boolean;
};

export enum HardwareConditionReference {
  New = 'New',
  Used = 'Used',
  Undefined = '',
}

export type MappedHardwareListItem = {
  name: string;
  modelName: string;
  variant: HardwareVariant;
  colors: HardwareColor[];
  condition: HardwareConditionReference;
  itemStatus: HardwareItemStatus | undefined;
  image: Image;
  itemUrl: string;
  listStickerText: string;
  salesStatus: HardwareSalesStatus;
  hasPriceRange: boolean;
  brand: HardwareBrand;
  category: HardwareCategory;
  numberOfMemorySizes: number;
};

export type HardwareStockStatuses = Record<string, StockStatus>;

export type HardwareProductMetaData = {
  products: string[];
  productData: Product[];
  hardwareStockStatuses?: HardwareStockStatuses;
};

export type HardwareProductCmsData = {
  $type: CmsPageType.HardwareProduct;
  id: number;
  brand: HardwareBrand;
  brandType: Brand;
  category: HardwareCategory;
  colors: BlockListItem<HardwareColor>[];
  condition: HardwareConditionReference;
  subscriptionCampaigns: BlockListItem<HardwareCampaign>[];
  listStickerText: string;
  memorySizes: {
    $values: string[];
  };
  metaData?: HardwareProductMetaData;
  modelName: string;
  name: string;
  productInformation: BlockListItem<CmsHeaderBody>[];
  salesStatus: HardwareSalesStatus;
  samsungTradeIn: boolean;
  specialDealHeader?: string;
  specialDealBody?: string;
  specialDealCollapsedLabel?: string;
  specialDealExpandedLabel?: string;
  specialDealCollapsedBody?: string;
  variants: BlockListItem<HardwareVariant>[];
  url: string;
} & CmsPageSeo;

export type HardwareListPageCmsData = {
  $type: CmsPageType.HardwareListingPage;
  blocks: BlockListItem<BlockListItemType>[];
  bottomBlocks: BlockListItem<BlockListItemType>[];
  hardwareProductList: {
    id: number;
    name: string;
    url: string;
    hardwareProducts: HardwareProductCmsData[];
  };
  name: string;
  metaData?: {
    hardwareStockStatuses?: HardwareStockStatuses;
  };
} & CmsPageSeo;

export type HardwareCondition = {
  $type: 'HardwareCondition';
  descriptionBody: string;
  descriptionHeader: string;
  iconReference: IconKey;
  id: number;
  label: string;
  name: string;
  reference: HardwareConditionReference;
  terms: BlockListItem<Usp>[];
  url: string;
};

export type HardwareLabelsCmsData = {
  $type: CmsPageType.HardwareProductPage;
  conditions: HardwareCondition[];
  deliveryComingSoon: string;
  deliveryFewInStock: string;
  deliveryFreeDelivery: string;
  deliveryInStock: string;
  deliveryOutOfStock: string;
  deliveryPreorder: string;
  listFrom: string;
  listInStockButtonText: string;
  listComingSoonButtonText: string;
  listOutOfStockButtonText: string;
  listPreorderButtonText: string;
  metaData: { products: string[]; productData: Product[] };
  productCampaignTabLabel: string;
  productComingSoonButtonText: string;
  productDescription: string;
  productDiscountValidUntil: string;
  productHeader: string;
  productInstalmentDuration: string;
  productMonthlyInstalmentUnit: string;
  productOrderNowButtonText: string;
  productPreorderButtonText: string;
  productRegularMonthlyInstalment: string;
  productSelectColorHeader: string;
  productSelectedColor: string;
  productSelectMemorySizeHeader: string;
  productSubheader: string;
  productTitle: string;
  subscriptionAlerts: [
    ErrorMessageWrappedInContent<'noAvailableSubscriptions'>,
    ErrorMessageWrappedInContent<'noAccountFound'>,
    ErrorMessageWrappedInContent<'notACustomer'>,
    ErrorMessageWrappedInContent<'brandMismatch'>
  ];
  subscriptionCancelBankID: string;
  subscriptionChange: string;
  subscriptionCheckoutButtonText: string;
  subscriptionDefaultVoiceOffers: BlockListItem<OfferListBlock>[];
  subscriptionDefaultMbbOffers: BlockListItem<OfferListBlock>[];
  subscriptionExistingSubscriptionTab: string;
  subscriptionHeader: string;
  subscriptionHelpTexts: BlockListItem<CmsHelpText>[];
  subscriptionLoginHeader: string;
  subscriptionLowestTotalCostBody: string;
  subscriptionLowestTotalCostHeader: string;
  subscriptionNewPrice: string;
  subscriptionNewSubscriptionTab: string;
  subscriptionNonContractNotAllowed: string;
  subscriptionOpenBankIdonOtherDevice: string;
  subscriptionOpenBankIdonSameDevice: string;
  subscriptionOtherDeviceBankIdbuttonText: string;
  subscriptionSameDeviceBankIdbuttonText: string;
  subscriptionSelectSubscriptionHeader: string;
  subscriptionSubscriptionAlreadyConnected: string;
  subscriptionVoiceUsps: {
    $values: string[];
  };
  vatInfo: string;
  mbbUsps: {
    $values: string[];
  };
};
