import { PageSlugPrefix } from '@/constants';
import { StickerVariant } from '@/newComponents/Sticker/StickerTypes';

import { HallonColor } from '@/global/utils/styleTypes';

// Endpoints is BE cms-api
export enum CmsRouteKey {
  Abonnemang = 'abonnemang',
  Aktiveraesim = 'aktiveraesim',
  BestallSim = 'bestallsim',
  BytAbonnemang = 'bytabonnemang',
  CancelSubscription = 'cancelSubscription',
  CardPayment = 'cardpayment',
  ConvertToExtraUserModal = 'convert-to-extra-user-modal',
  ExtraUsers = 'extrausers',
  FeatureFlags = 'featureFlags',
  HardwareLabelsB2C = 'hardware-labels-b2c',
  HardwareLabelsB2B = 'hardware-labels-b2b',
  HardwareList = 'hardware-list',
  HardwareProduct = 'hardware-product',
  HardwareListPage = 'hardware-list-page',
  Login = 'login',
  MyPagesContent = 'my-pages-content',
  OrderBekraftelse = 'orderbekraftelse',
  Payment = 'payment',
  SelectMainSubscriptionModal = 'select-main-subscription-modal',
  UtlandspriserB2C = 'internationalPricesPageB2C',
  UtlandspriserB2B = 'internationalPricesPageB2B',
  Settings = 'settings',
  SurfLimitComponent = 'surf-limit-component',
  SurfLimitModal = 'surf-limit-modal',
  Varukorg = 'varukorg',
  Unsubscribe = 'unsubscribe',
  Paus = 'paus',
  ProposalB2C = 'proposal-b2c',
  ProposalB2B = 'proposal-b2b',
}

// Next-api endpoints for fetching cmsContent
export const CmsRoute: Record<string, string> = {
  [CmsRouteKey.Abonnemang]: '/mina-sidor/abonnemang',
  [CmsRouteKey.Aktiveraesim]: '/mina-sidor/aktiveraesim',
  [CmsRouteKey.BestallSim]: '/mina-sidor/bestallsim',
  [CmsRouteKey.BytAbonnemang]: '/mina-sidor/bytabonnemang',
  [CmsRouteKey.CancelSubscription]: '/mina-sidor/avsluta',
  [CmsRouteKey.CardPayment]: '/cardpayment',
  [CmsRouteKey.ConvertToExtraUserModal]: '/convert-to-extra-user-modal',
  [CmsRouteKey.ExtraUsers]: '/mina-sidor/extrausers',
  [CmsRouteKey.FeatureFlags]: '/feature-toggles',
  [CmsRouteKey.HardwareLabelsB2C]: '/b2c-product-page',
  [CmsRouteKey.HardwareLabelsB2B]: '/b2b-product-page',
  [CmsRouteKey.HardwareList]: '/mobiler',
  [CmsRouteKey.HardwareProduct]: '/mobiler', // this is the prefix for the matching CMS page /mobiler/iPhone-15
  [CmsRouteKey.Login]: '/login',
  [CmsRouteKey.MyPagesContent]: '/mina-sidor/',
  [CmsRouteKey.OrderBekraftelse]: '/orderbekraftelse',
  [CmsRouteKey.Payment]: '/payment',
  [CmsRouteKey.SelectMainSubscriptionModal]: '/select-main-subscription-modal',
  [CmsRouteKey.Settings]: '/settings',
  [CmsRouteKey.SurfLimitComponent]: '/surf-limit-component',
  [CmsRouteKey.SurfLimitModal]: '/surf-limit-modal',
  [CmsRouteKey.Varukorg]: '/varukorg',
  [CmsRouteKey.UtlandspriserB2C]: '/utlandspriser',
  [CmsRouteKey.UtlandspriserB2B]: '/foretag/utlandspriser',
  [CmsRouteKey.Unsubscribe]: '/avregistrera',
  [CmsRouteKey.Paus]: '/mina-sidor/paus',
  [CmsRouteKey.ProposalB2C]: '/sales-flow-b2c',
  [CmsRouteKey.ProposalB2B]: '/sales-flow-b2b',
};

export type SlugResponseBaseData = {
  $type: string;
  id: string | number;
  url: string;
  redirectUrl?: string;
  metaData?: Record<string, unknown>;
};

export type FetchCmsContentItem = {
  key: CmsRouteKey | typeof PageSlugPrefix;
  suffix?: string;
  queryParams?: Record<string, string | string[] | boolean>;
};

export type BlockListItem<Content> = {
  content: Content;
  contentUdi: string;
  settings: string | null;
  settingsUdi: string | null;
};

export type Usp = {
  description: string;
  icon: string;
};

export enum NotificationType {
  IssueCritical = 'Issue-critical',
  IssueMinor = 'Issue-minor',
  Information = 'Information',
  Ok = 'OK',
}

export type UspsWithoutIcons = {
  $type: string;
  $values: string[];
};

export type Image = {
  id: number;
  name: string;
  url: string;
};

export type ErrorMessage<ReferenceKey> = {
  body: string;
  buttonLink?: string;
  buttonText?: string;
  header?: string;
  referenceKey?: ReferenceKey;
};

// Added to all content pages
export type ContentPageSuffix = {
  id: number;
  name: string;
  url: string;
};

export type Link = {
  name: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'] | null;
  type?: number;
  udi?: string | null;
  url: string;
};

export type CmsHelpText = {
  alias: string;
  header: string;
  text: string;
};

export type CmsHeaderBody = {
  header: string;
  body: string;
};

export enum OfferType {
  Offer = 'Offer',
  Hardware = 'Hardware',
}

export type Offer = {
  $type: OfferType;
  campaignKeys?: string;
  expiryDate?: string;
  extraUsp?: string;
  offerDuration?: string;
  offerPrice: string;
  ratorPackageId: string;
  previousCampaignPrice: string;
  regularSurfAmount: string;
  welcomeData: string;
} & ContentPageSuffix;

export type OfferListItem = {
  offer?: Offer;
  offerCallout?: string;
  offerName: string;
  shortlisted: boolean;
  highlight: boolean;
};

export type OfferListBlock = {
  offerList: {
    offers: BlockListItem<OfferListItem>[];
  } & ContentPageSuffix;
  offerListName: string;
  onlyDisplayShortlisted: boolean;
};

export enum NeutralCmsThemeKey {
  White = 'White',
  LightHallon = 'LightHallon',
  DarkHallon = 'DarkHallon',
}

export enum BlockWithStickerCmsThemeKey {
  Image = 'Image',
  White = 'White',
  LightHallon = 'LightHallon',
  DarkHallon = 'DarkHallon',
  UsedHardware = 'UsedHardware',
}

export enum CountdownCmsThemeKey {
  MineShaft = 'Mineshaft',
  Gray = 'Gray',
  DarkHallon = 'DarkHallon',
  LightHallon = 'LightHallon',
  White = 'White',
  UsedHardware = 'UsedHardware',
}

export enum MiniTeasersCmsThemeKey {
  Mixed = 'Mixed',
  Gray = 'Gray',
  DarkHallon = 'DarkHallon',
}

export enum UspCmsThemeKey {
  White = 'White',
  Gray = 'Gray',
}

export enum StickyUspBannerThemeKey {
  DarkHallon = 'DarkHallon',
  LightHallon = 'LightHallon',
  UsedHardware = 'UsedHardware',
}

export enum HeadlineBlockTheme {
  White = 'White',
  Grey = 'Grey',
}

export enum LegalBlockTheme {
  White = 'White',
  Gray = 'Gray',
  LightHallon = 'LightHallon',
}

export enum FaqBlockTheme {
  White = 'White',
}

export type NeutralCmsTheme = {
  backgroundColor: HallonColor;
  foregroundColor: HallonColor;
  linkColor: HallonColor;
  transparentSecondaryButton?: boolean;
};

export type BlockWithStickerCmsTheme = {
  backgroundColor: HallonColor;
  foregroundColor: HallonColor;
  linkColor: HallonColor;
  stickerVariant: StickerVariant;
  transparentSecondaryButton?: boolean;
  textColorSecondaryButton?: HallonColor;
};

export type CountdownCmsTheme = {
  backgroundColor: HallonColor;
  foregroundColor: HallonColor;
  linkColor: HallonColor;
  transparentSecondaryButton?: boolean;
};

export type UspCmsTheme = {
  backgroundColor: HallonColor;
  foregroundColor?: HallonColor;
};

export type MiniTeasersCmsTheme = {
  backgroundColor: HallonColor;
  items: { foregroundColor: HallonColor; backgroundColor: HallonColor }[];
};

export type ActionPageInformationBlock = {
  $type: 'ActionPageInformationBlock';
  header: string;
  marketingBubbleText?: string;
  body: string;
  marketingImage?: Image;
  priceStickerPreheader: string;
  priceStickerHeader: string;
  priceStickerSubheader: string;
  priceStickerPreamble: string;
  infoStickerHeader: string;
  infoStickerPreamble1: string;
  infoStickerPreamble2: string;
};

type FaqQuestion = {
  $type: 'FaqQuestionBlock';
  answer: string;
  category1: boolean;
  category2: boolean;
  category3: boolean;
  category4: boolean;
  category5: boolean;
  category6: boolean;
  category7: boolean;
  category8: boolean;
  question: string;
};

export type FaqBlock = {
  $type: 'FaqBlock';
  allCategoriesLabel: string;
  bleed: boolean;
  category1Label: string;
  category2Label: string;
  category3Label: string;
  category4Label: string;
  category5Label: string;
  category6Label: string;
  category7Label: string;
  category8Label: string;
  header: string;
  image: Image;
  mobileImage: Image;
  moreInformation: string;
  questions: BlockListItem<FaqQuestion>[];
  theme: FaqBlockTheme;
};
