export enum CmsLoginErrorReferenceKey {
  Generic = 'generic',
  Blacklisted = 'blacklisted',
  InvalidCredentials = 'invalidCredentials',
  LoginRateLimit = 'loginRateLimit',
  InvalidEmailFormat = 'invalidEmailFormat',
  BankIDTimeout = 'bankIDTimeout',
  InvalidPersonalNumberFormat = 'invalidPersonalNumberFormat',
  External = 'external',
  Internal = 'internal',
  BankIDAlreadyInProgress = 'bankIDAlreadyInProgress',
  RedisDown = 'redisDown',
  RequiredField = 'requiredField',
  InvalidToken = 'invalidToken',
}
