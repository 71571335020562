export type LegacyPaymentData = {
  returnUrl: string;
  amount: number;
  cancelUrl: string;
  reference: string;
  payType: string; // Todo create enum of all the options
  sessionid: string;
};

export enum InvoiceStatus {
  BillogramInvoice = 'BillogramCaptured',
  ArvatoInvoice = 'ArvatoInvoice', // Specialcase, status not from Rator
  DoAuthorize = 'DoAuthorize',
  Authorized = 'Authorized',
  AuthorizeSent = 'AuthorizeSent',
  DoCapture = 'DoCapture',
  CaptureSent = 'CaptureSent',
  Captured = 'Captured',
  Withdrawn = 'Withdrawn',
  Cancelled = 'Cancelled',
  DoCancel = 'DoCancel',
  DoRefund = 'DoRefund',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  RefundSent = 'RefundSent',
  Rejected = 'Rejected',
  Error = 'Error',
  Unknown = 'Unknown',
}
