import { Brand } from './account';

export enum ProductGroup {
  Ordinary = 'Ordinary',
  Student = 'Student',
  StudentOld = 'StudentOld',
  Senior = 'Senior',
  Unknown = 'Unknown',
}

export enum ProductRatePlan {
  NonContract = 'NonContract',
  Contract = 'Contract',
  Empty = '',
}

export enum ProductType {
  Voice = 'Voice',
  Broadband = 'Broadband',
  Mobile = 'Mobile',
  Router = 'Router',
  Hardware = 'Hardware',
  Unknown = 'unknown',
  Empty = '',
}

export enum ProductCode {
  MbbRecurrent = 'MBB_RECURRENT',
  MbbNonRecurrent = 'MBB_NON_RECURRENT',
  Recurrent = 'RECURRENT',
  NonRecurrent = 'NON_RECURRENT',
  CompanyBase = 'FORETAG_BASE',
  MbbCompany = 'MBB_FORETAG',
  Empty = '',
}

export type Price = {
  amount: number;
  amountExclVat: number;
  unit: string;
};

export type Product = {
  brand: Brand;
  dataAmount: number;
  dataEuAmount: number;
  group: ProductGroup;
  id: string;
  productCode: ProductCode;
  productKey: string;
  name: string;
  packageWeight: number;
  price?: Price;
  ratePlan: ProductRatePlan;
  type: ProductType;
};
