import { CmsPageType } from '@/constants';
import {
  BlockListItem,
  BlockWithStickerCmsThemeKey,
  CountdownCmsThemeKey,
  HeadlineBlockTheme,
  Image,
  LegalBlockTheme,
  Link,
  MiniTeasersCmsThemeKey,
  NeutralCmsThemeKey,
  Offer,
  OfferListBlock,
  StickyUspBannerThemeKey,
  UspCmsThemeKey,
  UspsWithoutIcons,
} from '@/types/cms/generalCmsData';
import { Product } from '@/types/product';
import { ButtonColor } from '@/newComponents/Button/Button.utils';

export enum ComponentBlockType {
  CountdownHeroBlock = 'CountdownHeroBlock',
  CrossSellingBlock = 'CrossSellingBlock',
  DetailedOffers = 'DetailedOffers',
  FullWidthTeaserBlock = 'FullWidthTeaserBlock',
  HalfWidthTeaserBlock = 'HalfWidthTeaserBlock',
  HalfWidthImageBlock = 'HalfWidthImageBlock',
  HeadlineBlock = 'HeadlineBlock',
  HeroBannerflowBlock = 'HeroBannerflowBlock',
  MiniTeasersBlock = 'MiniTeasersBlock',
  OfferCardsBlock = 'OfferCardsBlock',
  RawHtmlblock = 'RawHtmlblock',
  FullWidthRoundedImageTeaserBlock = 'FullWidthRoundedImageTeaserBlock',
  RichTextBlock = 'RichTextBlock',
  UspBlock = 'UspBlock',
  StickyUspBanner = 'StickyUspbanner',
  LegalBlock = 'LegalBlock',
}

export enum NonComponentBlockType {
  ReusableBlock = 'ReusableBlock',
}

type BlockCommon = {
  $type: ComponentBlockType | NonComponentBlockType;
};

type HeroBlockCommon = {
  backgroundColor?: string; // TODO REMOVE_OLD_IDENTITY perhaps remove in favor of theme?
  header: string;
  subheader?: string;
  textPosition: 'Left' | 'Right';
} & BlockCommon;

type HeroButtonType = {
  buttonType: ButtonColor;
};

type HeroBlockLink = {
  name: string;
  target: string | null;
  type: number;
  udi: null;
  url: string;
};

export type TeaserBlock = {
  $type: string;
  content: {
    $type: 'MiniTeaserItem';
    header: string;
    image: Image;
    link: Link;
    preheader: string;
  };
  contentUdi: 'umb://element/2fe6b5497b7648fbb56a6e0dcd7f372d';
  settings: null;
  settingsUdi: null;
};

export type CountdownHeroBlock = HeroBlockCommon &
  HeroButtonType & {
    theme?: CountdownCmsThemeKey;
    buttonLink: Link | null;
    buttonText?: string;
    countdownHeader?: string;
    disableLinkWhenCountdownReachesZero?: boolean;
    endDate: string;
    offer: Offer | null;
    onlyButtonIsClickable?: boolean;
    usps: UspsWithoutIcons;
  };

export type HeadlineBlock = {
  header: string;
  preamble?: string;
  theme?: HeadlineBlockTheme;
};

export type LegalBlock = {
  header?: string;
  body?: string;
  collapsedBody?: string;
  collapsedLabel?: string;
  expandedLabel?: string;
  theme?: LegalBlockTheme;
};

export type StickerCmsData = {
  priceStickerHeader: string;
  priceStickerPreheader?: string;
  priceStickerSubheader?: string;
  priceStickerPreamble?: string;
  infoStickerHeader?: string;
  infoStickerPreamble1?: string;
  infoStickerPreamble2?: string;
};

export type FullWidthRoundedImageTeaserBlock = {
  buttonText: string;
  buttonType: ButtonColor;
  image?: Image;
  mobileImage?: Image;
  link: Link;
  theme?: BlockWithStickerCmsThemeKey;
  offerReference?: Offer;
  onlyButtonsAreLinked?: boolean;
  bleed?: boolean;
} & StickerCmsData &
  HeroBlockCommon &
  HeroButtonType;

export type FullWidthTeaserBlock = {
  backgroundImage?: Image;
  buttonText?: string;
  link?: HeroBlockLink;
  offerReference?: Offer | null;
  onlyTheButtonIsLinked?: boolean;
  priceStickerPreheader?: string;
  priceStickerHeader?: string;
  priceStickerSubheader?: string;
  priceStickerPreamble?: string;
  infoStickerHeader?: string;
  infoStickerPreamble1?: string;
  infoStickerPreamble2?: string;
  theme?: BlockWithStickerCmsThemeKey;
  bleed?: boolean;
} & HeroBlockCommon &
  HeroButtonType;

export type HalfWidthTeaserBlock = {
  backgroundImage: Image;
  offerReference: Offer | null;
  onlyTheButtonIsLinked: true;
  theme?: BlockWithStickerCmsThemeKey;
  enableGradient?: boolean;
  link: HeroBlockLink;
  buttonText: string;
} & HeroBlockCommon &
  HeroButtonType;

export type MiniTeasersBlock = {
  header: string;
  teaserBlocks: TeaserBlock[];
  theme?: MiniTeasersCmsThemeKey;
} & BlockCommon;

export type HalfWidthImageBlock = {
  theme?: BlockWithStickerCmsThemeKey;
  buttonText: 'Läs mer och beställ';
  image: Image;
  link: Link;
  onlyTheButtonIsLinked: true;
} & StickerCmsData &
  HeroBlockCommon &
  HeroButtonType;

export type OfferCardsBlock = {
  buttonLink: Link | null;
  header: string;
  offerListBlocks: BlockListItem<OfferListBlock>[];
  complementaryHeader: string;
  complementaryUSPs: UspsWithoutIcons;
  uSps: { $values: string[] };
  renderDetails?: boolean;
  finePrint: string;
} & HeroBlockCommon & // TODO not all types in common exists on this entry, should i make a new type or pick out the ones that are used?
  HeroButtonType;

export type UspBlock = {
  theme?: UspCmsThemeKey;
  buttonLink: Link;
  buttonText: string;
  usp1Image: Image;
  usp1Text: string;
  usp2Image: Image;
  usp2Text: string;
  usp3Image: Image;
  usp3Text: string;
} & HeroBlockCommon &
  HeroButtonType;

export type StickyUspBlock = BlockCommon & {
  theme: StickyUspBannerThemeKey;
  header: string;
  usps: { $values: string[] };
  sticky: boolean;
};

export type RichTextBlock = {
  theme?: NeutralCmsThemeKey;
  body: string;
  image?: Image;
  mobileImage?: Image;
  mobileImagePosition: 'top' | 'bottom';
  button: Link;
  collapsible: boolean;
  collapsibleHeader?: string;
  singleColumn: boolean;
} & Omit<HeroBlockCommon, 'textColorScheme'> &
  HeroButtonType;

type ReusableBlockPage = {
  contentBlock: BlockListItem<UspBlock>[];
  id: number;
  name: string;
  url: string;
};

export type ReusableBlock = {
  contentBlock: ReusableBlockPage;
  label: string;
} & BlockCommon;

export type SavedeskPage = {
  $type: string;
  boxHeader: string;
  buttonText: string;
  cancelInfo: string;
  cancelInfoReactivated: string;
  confirmationBody: string;
  confirmationBoxHeader: string;
  confirmationHeader: string;
  confirmationSubheader: string;
  confirmationTeaserButton: HeroBlockLink;
  confirmationTeaserHeader: string;
  confirmationTeaserImage: Image;
  confirmationTeaserText: string;
  footnote: string;
  header: string;
  heroBubbleHeader: string;
  heroBubblePreamble: string;
  heroBubblePreheader: string;
  heroBubbleSubheader: string;
  heroHeader: string;
  heroSubheader: string;
  invalidBodyText: string;
  invalidButton: HeroBlockLink;
  invalidHeader: string;
  invalidSubHeader: string;
  priceUsp: string;
  savedSurfUsp: string;
  submitErrorText: string;
  validUntilText: string;
  pageDescription: string;
  pageRobots: string;
  pageSharingImage: null;
  pageTitle: string;
  id: number;
  name: string;
  url: string;
};

export type HeroBannerflowBlock = {
  bannerflowDesktopA: string;
  bannerflowDid: string;
  bannerflowMobileA: string;
  bGcolor?: string;
  bannerflowHeader?: string;
  bannerflowPreamble?: string;
  desktopHeight?: '196' | '336' | '576' | '752';
  mobileHeight?: '96' | '196' | '296' | '504';
} & BlockCommon;

type CrossSellingFaq = {
  $type: string;
  content: {
    $type: string;
    alias: string;
    header: string;
    text: string;
  };
};

type CrossSellingUsp = {
  $type: string;
  content: {
    $type: string;
    text: string;
  };
};

export type CrossSellingBlockContent = {
  $type: string;
  theme: NeutralCmsThemeKey;
  header: string;
  buttonLink?: Link;
  buttonText?: string;
  buttonType?: ButtonColor;
  faqs?: CrossSellingFaq[];
  infoStickerHeader?: string;
  infoStickerLine2?: string;
  infoStickerLine3?: string;
  offer?: Offer;
  priceStickerHeader?: string;
  priceStickerLine4?: string;
  priceStickerPreHeader?: string;
  priceStickerSubHeader?: string;
  usps?: CrossSellingUsp[];
};

export type CrossSellingBlock = BlockCommon & {
  content: CrossSellingBlockContent;
};

export type BlockListItemType =
  | ReusableBlock
  | HeroBannerflowBlock
  | OfferCardsBlock
  | CountdownHeroBlock
  | MiniTeasersBlock
  | HalfWidthImageBlock
  | HalfWidthTeaserBlock
  | FullWidthRoundedImageTeaserBlock
  | UspBlock
  | FullWidthTeaserBlock
  | CrossSellingBlock
  | StickyUspBlock;

export type CmsPageBase = {
  id?: number;
  name?: string;
  url?: string;
};

export type CmsPageSeo = {
  pageDescription?: string;
  pageRobots?: string;
  pageSharingImage?: Image | null;
  pageTitle?: string;
};

export type CmsPageTracking = {
  n2LandingPageId?: string;
  salesAgent?: string;
  salesChannel?: string;
};

export type CmsPageUrlSettings = {
  umbracoUrlName?: string;
};

// Resolved cms metaData string[] -> Product[] in [[...slug]].js
export type MetaData = {
  products: { data: Product[] };
};

/**
 * Landing page
 * almost like the startPage, but for partners e.g. Coop
 */

export type LandingPage = {
  $type: CmsPageType;
  blocks?: BlockListItem<BlockListItemType>[];
  metaData: MetaData;
} & CmsPageSeo &
  CmsPageTracking &
  CmsPageUrlSettings &
  CmsPageBase;

export type ArticlePage = {
  blocks?: BlockListItem<BlockListItemType>[];
  body?: string;
  headline?: string;
  heroImage?: Image | null;
  preamble?: string;
  preambleCountry?: string;
  titleCountry?: string;
} & CmsPageSeo &
  CmsPageTracking &
  CmsPageUrlSettings &
  CmsPageBase;

export type ErrorPage = {
  $type: 'ErrorPage';
  buttonLink: Link;
  buttonText: string;
  headline: string;
  preamble: string;
  body: string;
} & CmsPageSeo;

export type TrackingPage = {
  gclid: string | null;
  trackingreferer: string;
  utm_medium: null;
  utm_source: null;
};

export type TrackingCmsProduct = {
  cmsContextPageId: number | null;
  salesChannel: string | null;
  salesAgent: string | null;
};

export type UnsubscribePage = {
  body: string;
  buttonTextReSubscribe: string;
  errorBody: string;
  errorHeader: string;
  errors: [];
  header: string;
  headerReSubscribe: string;
  reSubscribedBody: string;
  reSubscribedHeader: string;
  name: string;
} & CmsPageSeo;
