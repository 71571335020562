import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';

import { ActionPageInformationBlock, BlockListItem, ContentPageSuffix, ErrorMessage, FaqBlock } from './generalCmsData';

type Modal = {
  $type: string;
  body: string;
  buttonText: string;
  header: string;
  reference: string;
};

type PausedCardUsp = {
  $type: string;
  description: string;
  icon: IconKey;
};

type ToPayHelp = {
  $type: string;
  alias: string;
  header: string;
  text: string;
};

export enum PausePriceReference {
  B2cVoice = 'B2C_VOICE',
  B2cMbb = 'B2C_MBB',
}

type PausePrice<Reference> = {
  reference: Reference;
  campaignPrice: number;
  campaignDuration: number;
  regularPrice: number;
};

export type PausSubscriptionCmsData = ContentPageSuffix & {
  generalErrors: BlockListItem<ErrorMessage<string>>[];
  buttonText: string;
  faq: BlockListItem<FaqBlock>[];
  generalSubscriptionLinkText: string;
  modals: BlockListItem<Modal>[];
  notPausedCardHeader: string;
  notPausedCardInfo: string;
  notPausedCardPriceHeader: string;
  notPausedCardPriceText: string;
  notPausedCardUsps: BlockListItem<PausedCardUsp>[];
  notPausedHeader: string;
  notPausedInformation: BlockListItem<ActionPageInformationBlock>[];
  notPausedSubheader: string;
  pausedBody: string;
  pausedButtonText: string;
  pausedCostText: string;
  pausedHeader: string;
  pausedInformation: BlockListItem<ActionPageInformationBlock>[];
  pausedNoCostText: string;
  pausedPaymentInformation: string;
  pausedSubHeader: string;
  pausedToPayHeader: string;
  pausedToPayHelp: BlockListItem<ToPayHelp>[];
  pausedToPayLineItemPrice: string;
  pausedToPayPeriod: string;
  pausedToPaySum: string;
  pausedToPaySumPrice: string;
  pausedToPayVat: string;
  pausedToPayVatprice: string;
  pendingButtonText: string;
  pendingHeader: string;
  pendingSubheader: string;
  pendingPauseInformation: BlockListItem<ActionPageInformationBlock>[];
  prices: BlockListItem<PausePrice<PausePriceReference>>[];
};

export enum PauseModalReference {
  PendingPauseModal = 'pendingPauseModal',
  RegretPendingPauseModal = 'regretPendingPauseModal',
  UnpauseModal = 'unpauseModal',
}
