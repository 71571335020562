import type {
  BankIdResponseLink,
  BlockListItem,
  CmsLoginErrorReferenceKey,
  ErrorMessageWrappedInContent,
  Notice,
} from '@/types';

export enum LoginErrorCode {
  Generic = '1',
  External = '2',
  NotACustomer = '3',
  Internal = '4',
  RedisDown = '5',
}

export type BankIdMessage = {
  step?: string;
  text?: string;
};

export enum BankIdProvider {
  BankIdOtherDevice = 'al-bankid-auth-otherdevice',
  BankIdSameDevice = 'al-bankid-auth-samedevice',
}

export type BankIdChallengePayload = {
  identityNumber?: string;
  returnUrl?: string;
  provider: BankIdProvider;
};

export type BankIdInitializePayload = {
  loginOptions?: string;
  returnUrl: string;
};

export type BankIdParams = {
  orderReference: string | null;
  cancelUrl: BankIdResponseLink;
  statusUrl: BankIdResponseLink;
  provider: BankIdProvider;
};

export type InitializeBankIdData = {
  orderReference: string | null;
  redirectUrl: BankIdResponseLink;
  statusUrl: BankIdResponseLink;
  cancelUrl: BankIdResponseLink;
  errorKey?: null;
};

export type InitializeBankIdError = {
  orderReference?: null;
  redirectUrl?: null;
  statusUrl?: null;
  cancelUrl?: null;
  errorKey: string;
};

export type BankIdLabels = {
  text?: string;
};

export type LoginCmsData = {
  createBody: string;
  createButtonText: string;
  createConfirmBody: string;
  createConfirmButtonText: string;
  createConfirmHeader: string;
  createErrors: [ErrorMessageWrappedInContent<'generic'>, ErrorMessageWrappedInContent<'passwordMismatch'>];
  createHeader: string;
  createHintBody: string;
  createHintHeader: string;
  createInvalidLinkBody: string;
  createInvalidLinkHeader: string;
  createNotEqual: string;
  createPassword_Label: string;
  createPassword_Placeholder: string;
  createRepeatPassword_Label: string;
  createRepeatPassword_Placeholder: string;
  createStrengthAverage: string;
  createStrengthStrong: string;
  createStrengthWeak: string;
  id: number;
  loginBankIdcancel: string;
  loginBankIdHelp?: BlockListItem<{
    alias: string;
    header: string;
    text: string;
  }>[];
  loginBankIdmobile: string;
  loginBankIdotherDevice: string;
  loginBankIdsameDevice: string;
  loginButtonText: string;
  loginCreatePassword: string;
  loginErrors: [
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.Generic>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.Blacklisted>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.InvalidCredentials>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.LoginRateLimit>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.InvalidEmailFormat>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.BankIDTimeout>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.InvalidPersonalNumberFormat>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.External>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.Internal>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.BankIDAlreadyInProgress>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.RedisDown>,
    ErrorMessageWrappedInContent<CmsLoginErrorReferenceKey.RequiredField>
  ];
  loginFieldEmail_Label: string;
  loginFieldEmail_Placeholder: string;
  loginFieldPassword_Label: string;
  loginFieldPassword_Placeholder: string;
  loginFieldPersonalNumber_Label: string;
  loginFieldPersonalNumber_Placeholder: string;
  loginHeader: string;
  loginHelp: [];
  loginIdentifyInstructionsOtherDevice: string;
  loginIdentifyInstructionsSameDevice: string;
  loginLoginWithBankID: string;
  loginLoginWithBankIdHeader: string;
  loginLoginWithEmail: string;
  loginOpenBankIdmobile: string;
  loginOpenBankIdotherDevice: string;
  loginOpenBankIdsameDevice: string;
  loginQrScanInstructions: string;
  loginQrScanned: string;
  loginSectionB2B: string;
  loginSectionB2C: string;
  loginStatusAcceptedHeader: string;
  loginStatusAcceptedSubheader: string;
  loginStatusNoAccount: string;
  loginStatusPreparing: string;
  loginStatusResuming: string;
  loginTryAgain: string;
  name: string;
  requestBack: string;
  requestBody: string;
  requestButtonText: string;
  requestCancel: string;
  requestConfirmBody: string;
  requestConfirmHeader: string;
  requestEmail_Label: string;
  requestEmail_Placeholder: string;
  requestErrors: [ErrorMessageWrappedInContent<'generic'>, ErrorMessageWrappedInContent<'invalidEmailFormat'>];
  requestHeader: string;
  requestHelpBody: string;
  requestHelpHeader: string;
  url: string;
};

export type LoginValidationLabels = {
  genericError: string;
  blackListed: string;
  invalidMailPass: string;
  noAccount: string;
  loginLimit: string;
  invalidMail: string;
  preparingLogin: string;
  resumingLogin: string;
  openBankIdMobile: string;
  openBankIdDesktop: string;
  bankdIdTimeout: string;
  invalidIdentity: string;
  external: string;
  internal: string;
  backtoLogin: string;
  tryAgain: string;
  alreadyInProgress: string;
  redisDown: string;
  passwordStrengthWeak: string;
  passwordStrengthAverage: string;
  passwordStrengthStrong: string;
  passwordDoesNotMatch: string;
};

export enum GtmLoginType {
  Business = 'business',
  Consumer = 'consumer',
}

export enum GtmLoginMethod {
  BankId = 'bankid',
  Email = 'email',
}

export type EmailPasswordLoginPayload = {
  email: string;
  password: string;
  returnUrl: string;
  isB2b: boolean;
};

export type ApiLoginErrorResponse = {
  errorCode?: string;
  message: string;
  type: number;
};

export enum ResetPasswordFieldKey {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

export type ResetPasswordFormItem = {
  value: string;
  error?: Notice;
  valid: boolean;
  touched: boolean;
};

export type AutoLoginParams = {
  autoLoginPersonalId: string;
  autoLoginEmail: string;
  autoLoginBankIdReference: string;
  autoLoginReturnUrl: string;
};

export enum EmailPasswordFormField {
  Email = 'email',
  Password = 'password',
}

export type EmailPasswordFormItem = {
  value: string;
  error: Notice | undefined;
  valid: boolean;
  touched: boolean;
};
