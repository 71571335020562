import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { BlockListItemType } from '@/types/cms/startPageCmsData';

import { BlockListItem, ContentPageSuffix, Image } from './generalCmsData';

type Icon = {
  icon: string;
  text: string;
};

type CancelSubscriptionImage = {
  alias: string;
  buttonText: string;
  header: string;
  image: Image;
  link: string;
  mainBody: string;
};

type CancelSubscriptionRte = {
  dataLostNotice: string;
  header: string;
  mainBody: string;
};

type CancelSubscriptionIcons = {
  cancelButtonText: string;
  confirmButtonText: string;
  header: string;
  icons: Icon[];
};

type CancelSubscriptionList = (CancelSubscriptionImage | CancelSubscriptionRte | CancelSubscriptionIcons)[];

export type Blurb = {
  alias: string;
  buttonText: string;
  header: string;
  link?: string;
  icon: IconKey;
  iconBackgroundColor: string;
  iconColor: string;
  preamble: string;
};

type FocalPoint = {
  left: number;
  top: number;
};

enum CropAlias {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Xl = 'xl',
}

export type Crop = { alias: CropAlias; width: number; height: number; coordinates: null };

export type HeroImage = {
  src: string;
  focalPoint: FocalPoint;
  crops: Crop[];
};

type SubscriptionCta = {
  buttonText: string;
  header: string;
  subheader?: string;
  link: string;
  showOnApp: boolean;
  showOnWeb: boolean;
};

type MyPagesHelpText = {
  body: string;
  buttonLink: string;
  buttonText: string;
  header: string;
};

export type MyPagesCmsData = {
  accountHeader: string;
  accountHolder: string;
  accountSettingsLinkText: string;
  b2bBottomBlocks: BlockListItem<BlockListItemType>[];
  b2Bhero: BlockListItem<BlockListItemType>[];
  b2cBottomBlocks: BlockListItem<BlockListItemType>[];
  b2Chero: BlockListItem<BlockListItemType>[];
  cancelSubscription: CancelSubscriptionList;
  creditCardBlurbs: Blurb[];
  dataRemaining: string;
  expiredPaymentCard: string;
  extraUsersHeader: string;
  greeting: string;
  helpTexts: BlockListItem<MyPagesHelpText>[];
  heroImage: HeroImage;
  heroImageApp: HeroImage;
  heroImageB2B: HeroImage;
  initialSurf: string;
  isExternalLink: false;
  mainSubscriptionCanceled: string;
  numberPortingBlurbs: Blurb[];
  pausedLabel: string;
  paymentLinkText: string;
  paymentsMissing: string;
  sharedDataRemaining: string;
  sharedSurf: string;
  showInMenu: boolean;
  simCardBlurbs: Blurb[];
  soonExpiredPaymentCard: string;
  subscriptionBlurbs: Blurb[];
  subscriptionCtaB2B: SubscriptionCta[];
  subscriptionCtaB2C: SubscriptionCta[];
  subscriptionsHeader: string;
  surf: string;
  terminatedSurf: string;
  unlimited: string;
} & ContentPageSuffix;
