import { Account, Address, Compliance, PaymentInfo } from '@/types/account';
import { Product } from '@/types/product';

import { Invoice } from './invoice';
import { ESimCard, FriendVoucherType, ProductOptions, SimOrderStatus, Subscription } from './subscription';
import { Topup } from './topup';

export type PaymentMethodsApiResponse = {
  data: PaymentInfo;
};

export type InvoicesApiResponse = {
  data: Invoice[];
};

export type InvoicePdfApiResponse = {
  data: string;
};

export type AddressResponse = {
  data: Address;
};

export type ChangeEmailResponse = {
  data: string;
};

export type AccountResponse = {
  data: Account;
};

export type ChangeAliasResponse = {
  data: string;
};

export type RestartNumberPortingResponse = {
  data: Subscription;
};

export type GetSubscriptionResponse = {
  data: Subscription;
};

export type GetSubscriptionsResponse = {
  data: Subscription[];
};

export type GetProductsResponse = {
  data: Product[];
};

export type EmailPasswordLoginResponse = {
  data: {
    returnUrl: string;
  };
};

export type ComplianceResponse = {
  data: Compliance;
};

export type BankIdResponseLink = {
  action: 'GET' | 'POST';
  rel: 'returnUrl' | 'bankIdInitializeUrl' | 'statusUrl' | 'redirectUrl' | 'cancelUrl' | 'callbackUrl';
  href: string;
};

export type BankIdChallengeResponse = {
  loginOptions: string;
  links: BankIdResponseLink[];
};

export type BankIdInitializeResponse = {
  links: BankIdResponseLink[];
  orderReference?: string;
};

export enum BankIdAuthenticationStatus {
  Initial = 'Initial',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type BankIdPollStatusResponse = {
  errorKey?: string;
  isFinished: boolean;
  links: BankIdResponseLink[];
  qrCodeStartState: string;
  qrCodeData: string;
  status: BankIdAuthenticationStatus;
  resultData: string;
  antiforgeryToken: string | null;
};

export type InitializeIdentificationBankIdResponse = {
  links: BankIdResponseLink[];
  orderReference: string;
};

export type IdentificationBankIdStatusResponse = {
  errorKey?: string;
  isFinished: boolean;
  links: BankIdResponseLink[];
  qrCodeData: string;
  status: BankIdAuthenticationStatus;
};

export type ProductOptionsApiResponse = {
  data: ProductOptions;
};

export type GetSimCardsActivationStatusResponse = {
  data: {
    canActivate: boolean;
    status: SimOrderStatus;
  };
};

export type GetSimCardsChangeStatusResponse = {
  data: {
    status: SimOrderStatus;
    canChange: boolean;
  };
};

export type ActivateSimCardsChangeStatusResponse = {
  data: Subscription;
};

export type BlockSimCardsResponse = {
  data: Subscription;
};

export type UpdateSimCardsResponse = {
  data: Subscription;
};

export type GetEsimsResponse = {
  data: {
    eSimCards: ESimCard[];
  };
};

export type GetEsimCancelStatusResponse = {
  data: {
    canCancel: boolean;
  };
};

export type TopupsResponse = {
  data: Topup[];
};

export type BuyExtraSurfResponse = {
  data: Subscription;
};

export type GetSubscriptionInfoResponse = {
  numberOfActiveSubscriptions: number;
  canAddNumberOfSubscriptions: number;
};

export type GetSubscriptionVoucherResponse = {
  data: FriendVoucherType;
};
