import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import type {
  BlockListItemType,
  CmsPageSeo,
  ErrorMessageWrappedInContent,
  HardwareBrand,
  HardwareConditionReference,
  Notice,
  PaymentSession,
  ProductCode,
  ProductRatePlan,
  ProductType,
  StockStatus,
} from '@/types';
import { Brand, Customer } from '@/types/account';
import { BlockListItem, CmsHelpText, Image, OfferType } from '@/types/cms/generalCmsData';

import { ApiErrorCode } from './error';

export enum CheckoutCmsClientErrorReference {
  GENERIC = 'GENERIC',
  CLIENT_ERROR_CUSTOMER_NOT_FOUND = 'CLIENT_ERROR_CUSTOMER_NOT_FOUND',
  CLIENT_ERROR_CREATE_ORDER_FAILURE = 'CLIENT_ERROR_CREATE_ORDER_FAILURE',
  CLIENT_ERROR_DUPLICATE_NUMBER = 'CLIENT_ERROR_DUPLICATE_NUMBER',
  CLIENT_ERROR_FAILED_FETCH_PHONENUMBERS = 'CLIENT_ERROR_FAILED_FETCH_PHONENUMBERS',
  CLIENT_ERROR_INVALID_CREDENTIALS = 'CLIENT_ERROR_INVALID_CREDENTIALS',
  CLIENT_ERROR_INVALID_EMAIL_ADDRESS = 'CLIENT_ERROR_INVALID_EMAIL_ADDRESS',
  CLIENT_ERROR_INVALID_EMAIL_ADDRESS_FORMAT = 'CLIENT_ERROR_INVALID_EMAIL_ADDRESS_FORMAT',
  CLIENT_ERROR_INVALID_IDENTITY_NUMBER_FORMAT = 'CLIENT_ERROR_INVALID_IDENTITY_NUMBER_FORMAT',
  CLIENT_ERROR_INVALID_PHONENUMBER = 'CLIENT_ERROR_INVALID_PHONENUMBER',
  CLIENT_ERROR_INVALID_POWER_OF_ATTORNEY_IDENTIFIER = 'CLIENT_ERROR_INVALID_POWER_OF_ATTORNEY_IDENTIFIER',
  CLIENT_ERROR_NO_ACTIVE_CART = 'CLIENT_ERROR_NO_ACTIVE_CART',
  CLIENT_ERROR_NO_DATE_SELECTED = 'CLIENT_ERROR_NO_DATE_SELECTED',
  CLIENT_ERROR_UNDERAGE_CUSTOMER = 'CLIENT_ERROR_UNDERAGE_CUSTOMER',
  CLIENT_ERROR_AUTHORIZE_PAYMENT_FAIL_NEW_CARD = 'CLIENT_ERROR_AUTHORIZE_PAYMENT_FAIL_NEW_CARD',
  CLIENT_ERROR_AUTHORIZE_PAYMENT_FAIL_EXISTING_CARD = 'CLIENT_ERROR_AUTHORIZE_PAYMENT_FAIL_EXISTING_CARD',
  CLIENT_ERROR_BILLOGRAM_CREDIT_DENIED = 'CLIENT_ERROR_BILLOGRAM_CREDIT_DENIED',
  CLIENT_ERROR_PDF_GENERATION = 'CLIENT_ERROR_PDF_GENERATION',
  CLIENT_ERROR_INVALID_SALESPERSON = 'CLIENT_ERROR_INVALID_SALESPERSON',
  CLIENT_ERROR_REQUIRES_REIDENTIFICATION = 'CLIENT_ERROR_REQUIRES_REIDENTIFICATION',
}

export type SummaryListOfSubscriptionPaymentMethods<ReferenceType> = {
  content: {
    $type: string;
    descriptionForExistingCustomer: string;
    descriptionForNewCustomer: string;
    firstInvoiceDescription: string;
    monthlyCostDescription: string;
    paymentMethodName: string;
    paymentProviderName: string;
    referenceKey: ReferenceType;
  };
};

export type SummaryElement = {
  content: {
    alias: string;
    header: string;
    text: string;
  };
};

export type SummaryListOfInvoiceFeatures = {
  content: {
    $type: string;
    description: string;
    validForArvato: boolean;
    validForBillogram: boolean;
    validForNets: boolean;
  };
};

export enum PaymentMethodGateway {
  Adyen = 'ADYEN',
  Billogram = 'BILLOGRAM',
  Arvato = 'ARVATO',
  Riverty = 'RIVERTY',
}

export enum OrderPaymentMethod {
  CARD = 'Card',
  INVOICE = 'Invoice',
}

export type CheckoutCmsData = {
  bankIdIntroText: string;
  brand: Brand;
  cartAddVoucherLinkText: string;
  cartChangeLinkText: string;
  cartConnectedToSubscription: string;
  cartHeader: string;
  cartNextStepButtonText: string;
  cartRemoveVoucherLinkText: string;
  cartShareDataWith: string;
  cartVatInfo: string;
  cartVoucherLabel: string;
  errorMessages: ErrorMessageWrappedInContent<CheckoutCmsClientErrorReference | ApiErrorCode>[];
  confirmationPageReference: {
    name: string;
    target: null;
  };
  createAccountHeader: string;
  createAccountButtonText: string;
  identifyAuthenticateHeader: string;
  identifyBankIdButtonText: string;
  identifyBankIdFaq: BlockListItem<CmsHelpText>[];
  identifyCancelButtonText: string;
  identifyChangeLinkText: string;
  identifyCustomerInformation: string;
  identifyCustomerInformationHeader: string;
  identifyDeliveryAddressBody: string;
  identifyDeliveryAddressHeader: string;
  identifyEmailFieldInstruction: string;
  identifyEmailFieldLabel: string;
  identifyExistingCustomerTabLabel: string;
  identifyHeader: string;
  identifyIdNumberInstruction: string;
  identifyIdNumberLabel: string;
  identifyLoginButtonText: string;
  identifyLoginHelp: SummaryElement[];
  identifyLoginWithBankIdLinkText: string;
  identifyLoginWithEmailLinkText: string;
  identifyLogoutLinkText: string;
  identifyOpenBankIdOtherDevice: string;
  identifyOtherDeviceBankIdButtonText: string;
  identifySameDeviceBankIdButtonText: string;
  identifyStartOverLinkText: string;
  identifyMobileBankIdButtonText: string;
  identifyNewCustomerTabLabel: string;
  identifyNextStepButtonText: string;
  identifySelectAccountButtonText: string;
  identifySelectAccountHeader: string;
  identifyVerifiedWithBankId: string;
  nextStepButtonAriaLabel: string;
  loggedInWithBankId: string;
  numberModalB2Bfaq: BlockListItem<CmsHelpText>[];
  numberModalB2Cfaq: BlockListItem<CmsHelpText>[];
  numberModalCancelLinkText: string;
  numberModalDateHint: string;
  numberModalDateLabel: string;
  numberModalHeader: string;
  numberModalKeepNumberBody: string;
  numberModalKeepNumberTab: string;
  numberModalLoadingMoreNumbers: string;
  numberModalLoadMoreNumbers: string;
  numberModalNewNumberHeader: string;
  numberModalNewNumberTab: string;
  numberModalNumberOwnerLabel: string;
  numberModalOtherOwnerLabel: string;
  numberModalPhoneNumberHint: string;
  numberModalPhoneNumberLabel: string;
  numberModalPowerOfAttorneyDescription: string;
  numberModalPowerOfAttorneyHint: string;
  numberModalPowerOfAttorneyLabel: string;
  numberModalSaveButtonText: string;
  numberPanelBodyPlural: string;
  numberPanelBodySingular: string;
  numberPanelChangeLinkText: string;
  numberPanelChangePhoneNumberSettingsLinkText: string;
  numberPanelHeaderPlural: string;
  numberPanelHeaderSingular: string;
  numberPanelMoveToHallonText: string;
  numberPanelNewNumberText: string;
  numberPanelNextStepButtonText: string;
  numberPanelPortingChecklist: {
    $values: string[];
  };
  numberPanelPortingHeader: string;

  pageHeader: string;
  processingAwaitingPaymentConfirmation: string;
  processingCreatingOrder: string;
  selectOrCreateAccountHeader: string;
  summaryAboutThePurchaseHeader: string;
  summaryContractLabel: string;
  summaryElements: SummaryElement[];
  summaryFixedAmountUnit: string;
  summaryFixedCostsHeader: string;
  summaryGdprConsentHeader: string;
  summaryGdprConsentText: string;
  summaryGeneralTermsHeader: string;
  summaryGeneralTermsText: string;
  summaryGeneralTermsHwtext: string;
  summaryGoToPaymentButton: string;
  summaryGoToPaymentButtonInformation: string;
  summaryHardwareCostSummaryHeader: string;
  summaryHardwareMonthlyCostHeader: string;
  summaryHardwarePaymentHeader: string;
  summaryInvoiceHeader: string;
  summaryListOfInvoiceFeatures: SummaryListOfInvoiceFeatures[];
  summaryListOfSubscriptionPaymentMethods: [
    SummaryListOfSubscriptionPaymentMethods<PaymentMethodGateway.Adyen>,
    SummaryListOfSubscriptionPaymentMethods<PaymentMethodGateway.Billogram>,
    SummaryListOfSubscriptionPaymentMethods<PaymentMethodGateway.Riverty>
  ];
  summaryMonth: string;
  summaryNonContractLabel: string;
  summaryOnetimeCost: string;
  summaryOrderSummaryPdfLinkText: string;
  summaryPayNowHeader: string;
  summaryPeriodLabel: string;
  summaryPurchaseButton: string;
  summaryPurchaseButtonInformation: string;
  summaryPurchaseTermsRte: string;
  summaryRecurringAmountUnit: string;
  summaryRecurringCostsHeader: string;
  summaryStepHeaderWhenInvoice: string;
  summaryStepHeaderWhenPayment: string;
  summarySubscriptionCostSummaryHeader: string;
  summarySubscriptionPaymentHeader: string;
  summarySubscriptionVatInfo: string;
  summaryCardDetailsHeader: string;
  summarySumLabel: string;
  summaryThereafter: string;
  summaryVatLabel: string;
  voucherModalButtonText: string;
  voucherModalCancelLinkText: string;
  voucherModalHeader: string;
  voucherModalInputInstruction: string;
  voucherModalInputLabel: string;
  yourAccountHeader: string;
  id: 2092;
  name: string;
  url: string;
  welcomeSurf?: string;
} & CmsPageSeo;

export enum CheckoutStep {
  OrderStep = 'OrderStep',
  CustomerStep = 'CustomerStep',
  PhonenumberStep = 'PhonenumberStep',
  PaymentStep = 'PaymentStep',
}

export type StepsMap = Partial<Record<keyof typeof CheckoutStep, number>>;

export enum PaymentStepView {
  newCustomerContrac = 'newCustomerContrac',
  newCustomerNonContrac = 'newCustomerNonContrac',
  loggedInCustomerCard = 'loggedInCustomerCard',
  loggedInCustomerInvoice = 'loggedInCustomerInvoice',
}

export type CheckoutProps = {
  labels: CheckoutCmsData;
  cartIsLoading: boolean;
};

export enum CustomerType {
  NEW_CUSTOMER = 'new_customer',
  EXISTING_CUSTOMER = 'existing_customer',
}

export type PricePeriod = {
  price: number;
  priceFormatted?: string;
  startMonth: number;
  length: number;
};

export enum PhonenumberGroup {
  Broadband = 'Broadband',
  Voice = 'Voice',
  FixedNumber = 'FixedNumber',
}

export type PartPaymentPrice = {
  discountedPricePerMonthExclVat: number | null;
  discountedPricePerMonthInclVat: number | null;
  discountedPricePerMonthVat: number | null;
  discountedTotalPriceExclVat: number | null;
  discountedTotalPriceInclVat: number | null;
  discountedTotalVat: number | null;
  discountEndDate: string;
  formattedDiscountedPricePerMonthExclVat: string | null;
  formattedDiscountedPricePerMonthInclVat: string | null;
  formattedDiscountedPricePerMonthVat: string | null;
  formattedDiscountedTotalPriceExclVat: string | null;
  formattedDiscountedTotalPriceInclVat: string | null;
  formattedDiscountedTotalVat: string | null;
  formattedPricePerMonthExclVat: string;
  formattedPricePerMonthInclVat: string;
  formattedPricePerMonthVat: string | null;
  formattedTotalPriceExclVat: string | null;
  formattedTotalPriceInclVat: string;
  formattedTotalVat: string | null;
  instalments: number;
  pricePerMonthExclVat: number | null;
  pricePerMonthInclVat: number;
  pricePerMonthVat: number | null;
  totalPriceExclVat: number | null;
  totalPriceInclVat: number;
  totalVat: number | null;
};

export type LineItemCampaign = {
  price: number;
  priceExcludingVat: number;
  vat: number;
  startMonth: number;
  length: number;
};

export type LineItemParentSubscription = {
  alias: string | null;
  id: string;
  name: string;
  packageId: string;
  phoneNumber: string;
};

export type LineItemExistingSubscription = {
  alias: string;
  id: string;
  name: string;
  packageId: string;
  phoneNumber: string;
  serviceId: string;
  isAllowedAsParentSubscription: boolean;
};

export type HardwareLineItemInstalment = {
  price: number;
  startMonth: number;
  length: number;
};

export type HardwareBase = {
  brand: HardwareBrand;
  condition: HardwareConditionReference;
  colorName: string;
  isPreOrder: boolean;
  memorySize: string;
  modelName: string;
  productReference: string;
};

export type LineItemHardware = HardwareBase & {
  baseProductUrl: string;
  brand: string;
  colorCode: string;
  colorName: string;
  condition: HardwareConditionReference;
  instalment?: HardwareLineItemInstalment;
  isPreOrder: boolean;
  memorySize: string;
  modelName: string;
  modelQueryName: string;
  originalPrice?: number;
  partPaymentPrice: PartPaymentPrice;
  productReference: string;
  productType: ProductType;
  stockStatus: StockStatus;
};

export type LineItem = {
  campaign: LineItemCampaign | null;
  campaignKeys: string;
  cmsContextPageId: string;
  extraUsp: string;
  forHardwareConnectedOfferLineItemId?: string;
  id: string;
  hardware?: LineItemHardware | null;
  lastModified: string;
  lineItemType: OfferType;
  mainSimOptionId: string;
  name: string;
  packageId: string;
  existingSubscription: LineItemExistingSubscription | null;
  existingSubscriptionId: string | null;
  parentSubscription: LineItemParentSubscription | null;
  parentSubscriptionId: string | null;
  previousProductReference: string;
  price: number | null;
  priceExcludingVat: number | null;
  vat: number | null;
  productCode: ProductCode | null;
  productReference: string;
  productType: ProductType | null;
  ratePlan: ProductRatePlan | null;
  salesAgent: string;
  salesChannel: string;
  utmMedium: string;
  utmSource: string;
  voucherCode: string | null;
  welcomeData?: number;
};

export type PriceSummary = {
  lastDayOfMonth: number;
  orderDay: number;
  payNow: number;
  payNowFormatted: string;
  payNowVat: number;
  payNowVatFormatted: string;
  pricePeriods: PricePeriod[];
  totalValue: number;
  totalValueFormatted?: string;
  totalVat: number;
  totalVatFormatted?: string;
};

export type PriceSummaryCart = {
  lastDayOfMonth: number;
  orderDay: number;
  payNow: number;
  payNowVat: number;
  pricePeriods: PricePeriod[];
};

export type Cart = {
  customerContext: Brand;
  id: string;
  lastModified: string;
  lineItems: LineItem[];
  priceSummary: PriceSummaryCart;
  requiresAuthentication: boolean;
  userId: string | null;
};

export type CartApiResponse = {
  data: Cart;
};

export type AddVoucherApiResponse = {
  data: Cart;
};

export type AddressAndName = {
  firstName: string;
  lastName: string;
  fullName: string;
  address?: string;
  street: string;
  streetNumber: string;
  city: string;
  zip: string;
};

type NumberPortingFormItem<value = string> = {
  value?: value;
  error: Notice | undefined;
  valid: boolean;
  touched: boolean;
};

export type NumberPortingFormItems = {
  phoneNumber: NumberPortingFormItem;
  userIsCurrentOwner: NumberPortingFormItem<boolean>;
  powerOfAttorney: NumberPortingFormItem;
  date: NumberPortingFormItem<Date>;
};

export type NumberPortingFormKeys = keyof NumberPortingFormItems;

export enum B2BNewCustomerFormName {
  CompanyId = 'companyId',
  Email = 'email',
  Password = 'password',
  RepeatPassword = 'repeatPassword',
}

export type NewCustomerFormItem = {
  value: string;
  error: Notice | undefined;
  valid: boolean;
  touched: boolean;
};

export type GuestCustomerB2BFormData = {
  companyId: string;
  email: string;
  password: string;
};

export type GuestCustomer = {
  password?: string;
} & Omit<Customer, 'userId' | 'customerNumber'>;

export type CustomerAddressResponse = {
  data: GuestCustomer;
};

export type OrderUser = {
  customerContext: Brand;
  email: string;
  ownerId: string;
  userId: string;
  address: AddressAndName;
  paymentMethodType?: OrderPaymentMethod | null;
};

export enum OrderStatus {
  INITIAL = 'INITIAL',
  CONFIRMED = 'CONFIRMED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum OrderPaymentStatus {
  INITIAL = 'INITIAL',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  CONFIRMED_BY_PAYMENT_PROVIDER = 'CONFIRMED_BY_PAYMENT_PROVIDER',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

type OrderRowCampaign = {
  length: number;
  price: number;
  priceExcludingVat: number;
  vat: number;
  priceFormatted?: string;
  priceExcludingVatFormatted?: string;
  vatFormatted?: string;
  startMonth: number;
};

type PhoneNumberInfo = {
  isNumberPort?: boolean;
  phoneNumberReservationId?: string | null;
  phoneNumberToPort?: string | null;
  selectedPhoneNumber?: string | null;
};

export type OrderRowHardware = HardwareBase & {
  partPaymentPrice: PartPaymentPrice;
  productType: ProductType;
};

export type Prices = {
  lastDayOfMonth: number;
  orderDay: number;
  payNow: number;
  payNowFormatted: string;
  payNowVat: number;
  payNowVatFormatted: string;
};

export type OrderRow = {
  campaign?: OrderRowCampaign | null;
  campaignKeys?: string | null;
  existingSubscription?: LineItemExistingSubscription | null;
  existingSubscriptionId?: string | null;
  hardware?: OrderRowHardware | null;
  hasCostSummaryPdf: boolean;
  hasPhysicalSimCard: boolean;
  id: string;
  lineItemType: OfferType;
  name?: string | null;
  originalPrice?: number | null;
  originalPriceExclVat?: number | null;
  originalPriceVat?: number | null;
  packageId?: string | null;
  parentSubscriptionId?: string | null;
  phoneNumberInfo?: PhoneNumberInfo | null;
  prices?: Prices | null;
  productReference?: string | null;
  productType?: ProductType | null;
  ratePlan?: ProductRatePlan | null;
  voucherCode?: string | null;
};

export type CardInfo = {
  type: string;
  lastDigits: string;
  expireYear: string;
  expireMonth: string;
};

export type InvoiceInfo = {
  firstInvoiceDate: string;
  firstPeriodStartDate: string;
  firstPeriodEndDate: string;
};

export type PaymentMethod = {
  methodType: OrderPaymentMethod;
  paymentGateway: PaymentMethodGateway;
  cardInfo: CardInfo | null;
  invoiceInfo?: InvoiceInfo | null;
  isSavedMethod: boolean;
  isAllowed: boolean;
};

export enum ConsentType {
  Global = 'Global',
  Page = 'Page',
}

export type OrderConsent = {
  id: number;
  header: string;
  body: string;
  consentType: ConsentType;
  accepted: boolean;
  mandatory: boolean;
};

export type Order = {
  availablePaymentMethods: PaymentMethod[];
  errorCode?: string | null;
  createdAt: string;
  user: OrderUser;
  gdprAccepted: boolean;
  hasUpfrontPrice: boolean;
  id: string;
  orderRows: OrderRow[];
  orderStatus: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  paymentSessionReference: string | null;
  paymentConfirmedAt: string | null;
  priceSummary: PriceSummary;
  referencedCartId: string;
  transactionId: string | null;
  consents: OrderConsent[];
  submitOrderResult: {
    createdSubscriptionId: string;
    createdUserId: string;
  };
};

export type LineItemInput = {
  id: string;
  phoneNumber: string;
  isPhoneNumberPort: boolean;
};

export type OrderLineItemInput = {
  portingDate?: Date | null;
  portOtherOwner?: string | null;
} & LineItemInput;

export type CreateCartParams = {
  customerContext: Brand;
  userId?: string;
  lineItem: {
    productReference?: string;
    parentSubscriptionId?: string;
    hardwareProductReference?: string;
    connectHardwareToExistingSubscriptionId?: string;
    lineItemType: OfferType;
    utmSource?: string;
    utmMedium?: string;
    cmsContextPageId?: string | null;
    salesChannel?: string | null;
    salesAgent?: string | null;
  };
};

export type ConfirmOrderSuccess = {
  confirmed: true;
  order: null;
};

export type ConfirmOrderCreditDenied = {
  confirmed: false;
  order: Order;
};

export type OrderResponse = {
  data: Order;
};

export type ValidateOrderResponse = {
  data: {
    isValid: boolean;
    errorKey: ApiErrorCode.ERROR_CART_REQUIRES_IDENTIFICATION | '';
  };
};

export type ConfirmOrderError = {
  data: {
    message: {
      data: Order;
      metaData: {
        ERROR_CODE: ApiErrorCode;
      };
    };
    status: number;
    type: 'error';
  };
};

export type ApiErrorWithErrorCode = {
  data: {
    message: ApiErrorCode;
  };
  status: number;
  type: 'error';
};

export type ValidateOrderPayload = {
  cartId: string;
  userId?: string;
  personalNumber: string;
  identificationReference: string | null;
};

export enum OrderType {
  Checkout = 'Checkout',
  SalesFlow = 'SalesFlow',
}

export type CreateOrderPayload = {
  cartId: string;
  customerContext: Brand;
  ownerId: string;
  email: string;
  password?: string;
  userId?: string;
  salesChannel: 'checkout';
  salesAgent?: string;
  lineItemInputs: LineItemInput[] | OrderLineItemInput[] | null;
  identificationReference: string;
  orderType?: OrderType;
  salesFlowSmsRecipientPhoneNumber?: string;
};

export type ConfirmOrderPayload = {
  paymentSessionReference: string | null;
  paymentType: OrderPaymentMethod;
  termsAndConditionsAccepted: boolean;
  consents: { consentId: number; accepted: boolean }[];
};

export type ValidateUserAndFetchInfoArgs = {
  ownerId: string;
  email: string;
  brand: Brand;
};

export type ValidateUserAndFetchInfoResponse = {
  data: {
    user: GuestCustomer | null;
    emailValid: boolean;
    personalIdValid: boolean;
  };
};

export type ValidatedAddress = {
  user: AddressAndName & { personalId: string };
  personalIdValid: boolean;
};

export type AvailablePhonenumbersResponse = {
  data: string[];
};

export type ValdidatePortPhonenumbersResponse = {
  data: string;
};

export enum PhonenumberStepTab {
  New = 'new',
  Keep = 'keep',
}

export type OrderStatusResponse = {
  data: {
    orderStatus: OrderStatus;
    paymentStatus: OrderPaymentStatus;
    errorCode: ApiErrorCode;
  };
};

export type SalesFlowAccount = {
  userId: string;
  userName: string;
  hasActiveSubscription: boolean;
};

export type SalesFlowResponse = {
  data: {
    accounts?: SalesFlowAccount[];
    personalNumber: string;
    address: AddressAndName;
    isExistingCustomer: boolean;
  };
};

export type AdyenConfig = {
  userId?: string;
  reference: string;
  returnUrl: string;
  amount: string;
  paymentType: string;
  sessionid: string;
};

export type AdyenOnCreateSessionPayload = {
  adyenMerchantReference: string;
  adyenShopperReference: string;
  amount: number;
  authorized: boolean;
  cardType: string;
  createdAtUtc: string;
  expireMonth: string;
  expireYear: string;
  id: string;
  isProcessed: boolean;
  lastFourDigits: string;
  paymentType: string;
  pspReference: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  sessionId: string;
  ticket: string;
  userId: string;
};

export enum OrderInformationItemReference {
  NewPrice = 'new-price',
  Phone = 'phone',
  PhonePreorder = 'phonePreorder',
  PhysicalSIM = 'physicalSIM',
  Porting = 'porting',
  Router = 'router',
}

export type OrderInformationItem = {
  content: {
    $type: string;
    body: string;
    header: string;
    icon: IconKey;
    reference: OrderInformationItemReference;
  };
};

export type UpsellTeaser = {
  $type: string;
  contentUdi: string;
  content: {
    $type: string;
    bodyRte: string;
    buttonLink: string;
    buttonText: string;
    header: string;
    image: Image;
    reference: ProductType;
  };
};

export type ReceiptCmsData = {
  customerInformationHeader: string;
  customerReferenceBody: string;
  generalBody: string;
  header: string;
  subHeader: string;
  lineItem: string;
  orderInformationHeader: string;
  orderInformationItems: OrderInformationItem[];
  orderReferenceBody: string;
  orderReferenceHeader: string;
  shippingAddressBody: string;
  shippingAddressHeader: string;
  summaryHeader: string;
  upsellTeasers: UpsellTeaser[];
  yourOrderHeader: string;
  pageTitle: string;
  id: number;
  name: string;
  url: string;
  voiceCrossSellTeaser?: BlockListItem<BlockListItemType>[];
};

export enum GtmLineItemCategory {
  Recurring = 'recurring',
  Prepaid = 'prepaid',
  Unknown = 'unknown',
  Hw = 'HW',
}

export enum GtmLineItemCategory2 {
  Phoneplan = 'phoneplan',
  Broadband = 'broadband',
  Mobile = 'mobile',
  Hardware = 'hardware',
  Router = 'router',
  Unknown = 'unknown',
}

export type GtmLineItem = {
  item_id?: string | null;
  item_name?: string | null;
  promotion_name?: string;
  coupon?: string | null;
  currency: 'SEK';
  discount: number;
  index: number;
  item_brand: 'hallon' | string;
  item_category?: GtmLineItemCategory;
  item_category2?: GtmLineItemCategory2;
  item_list_name: string;
  price?: number | null;
  quantity: number;
};

export type AdditionalPaymentData = {
  cardPaymentSession?: PaymentSession | null;
};

export type NewAccountEmailField = {
  value: string;
  error?: Notice;
  valid: boolean;
  touched: boolean;
  loading: boolean;
};
