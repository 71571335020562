import { PaymentType } from '@/constants';
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError';

export enum AdyenResultCodes {
  AUTHORISED = 'Authorised',
  REFUSED = 'Refused',
  ERROR = 'Error',
}

export type SessionDataConfig = {
  userId: string | null;
  reference: string;
  returnUrl: string;
  amount: number;
  paymentType: PaymentType;
  sessionid?: string;
};

export type PaymentMethodsConfiguration = {
  card: {
    hasHolderName: boolean;
    holderNameRequired: boolean;
  };
};

export type AdyenResultPayload = {
  resultCode: string;
  refusalReasonCode?: string;
};

export type CheckoutInstance = {
  submit: () => void;
  unmount: () => void;
};

export type AdyenState = {
  errors: Record<string, string>;
  isValid: boolean;
  valid: {
    holderName: boolean;
  };
};

export type PaymentSession = {
  adyenMerchantReference: string;
  adyenShopperReference: string;
  amount: number;
  authorized: boolean;
  cardType: string;
  createdAtUtc: string;
  expireMonth: string;
  expireYear: string;
  id: string;
  isProcessed: boolean;
  lastFourDigits: string;
  paymentType: PaymentType;
  pspReference: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  sessionId: string;
  ticket: string;
  userId: string;
};

export type AdyenConfigurationObject = {
  environment: string;
  lang: string;
  translations: { [key: string]: string };
  clientKey: string;
  paymentSession: PaymentSession;
  onPaymentCompleted: (result: AdyenResultPayload) => void;
  onChange?: (state: AdyenState, component: CheckoutInstance) => void;
  onError: (error: AdyenCheckoutError, component: CheckoutInstance) => void;
  showPayButton: boolean;
  paymentMethodsConfiguration: PaymentMethodsConfiguration;
};
