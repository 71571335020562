import { PaymentInfo } from './account';

export enum InvoiceType {
  HighUsage = 'HighUsage',
  FirstInvoice = 'FirstInvoice',
  Normal = 'Normal',
}

export enum PaymentStatus {
  DoAuthorize = 'DoAuthorize',
  Authorized = 'Authorized',
  AuthorizeSent = 'AuthorizeSent',
  DoCapture = 'DoCapture',
  CaptureSent = 'CaptureSent',
  Captured = 'Captured',
  BillogramCaptured = 'BillogramCaptured',
  Withdrawn = 'Withdrawn',
  Cancelled = 'Cancelled',
  DoCancel = 'DoCancel',
  DoRefund = 'DoRefund',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  RefundSent = 'RefundSent',
  Rejected = 'Rejected',
  Error = 'Error',
  Unknown = 'Unknown',
}

export enum PaymentType {
  Arvato = 'Arvato',
  Billogram = 'Billogram',
  Unknown = 'Unknown',
}

export type AccountPayment = {
  paymentStatus: PaymentStatus;
  paymentType: PaymentType;
  invoiceId: string;
  billogram: string;
};

export type Invoice = {
  accountPayment?: AccountPayment;
  alias: string;
  closeDate: string;
  customerName: string;
  dueDate: string;
  endDate: string;
  id?: string;
  invoiceNumber: string;
  invoiceType: InvoiceType;
  invoiceTypeId: number;
  isOpen: boolean;
  isPaid: boolean;
  isPublished: boolean;
  paidAmount: number;
  paymentInfo: PaymentInfo;
  phoneNumber: string;
  startDate: string;
  subscriptionId: string;
  subscriptionName: string;
  subscriptionType: string;
  totalExclVat: number;
  totalVat: number;
};
